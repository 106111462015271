import React, { useState, useEffect } from "react";
import { NURSE_IMG1 } from "./assets/images/imagespath/imagepath";
import Footer from './components/Footer';

const TakeCarers = () => {
    const token = localStorage.getItem("auth_token");
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/get_take_carers/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data);
                console.log(data)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [token]);


    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className='main-content-wrap'>
                <div className='page-content'>
                    <div className="row">
                        {data.map((carer, index) => (
                            <div className="col-12 col-md-9" key={index}>
                                <div className="nurse-card">
                                    <div className="nurse-content">
                                        <div className="nurse-img-box">
                                            <img src={carer.image || NURSE_IMG1} alt={carer.first_name} />
                                        </div>
                                        <div>
                                            <h4 className="nurse-name">{carer.first_name} {carer.last_name}</h4>
                                            <p className="nurse-specialty">{carer.care_certifications|| 'Orthopedic'}</p>
                                            <div className="nurse-info-box">
                                                <p className="nurse-description">
                                                    <i className="fas fa-info-circle"></i> {carer.working_hours || '9 AM to 5 PM'}
                                                </p>
                                                <p className="nurse-experience">
                                                    <i className="fas fa-briefcase"></i> {carer.care_experience} years of experience
                                                </p>
                                                <p className="nurse-address">
                                                    <i className="fas fa-map-marker-alt"></i> {carer.address || 'Visakhapatnam'}
                                                </p>
                                                <p className="nurse-contact">
                                                    <i className="fas fa-phone"></i> Contact: {carer.phone_number || 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="nurse-card-bottom">
                                        <button className="nurse-btn btn-primary">Request Care Taker</button>
                                    </div> */}
                                </div>
                            </div>
                        ))}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default TakeCarers;