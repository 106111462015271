import React, { useState, useEffect } from 'react';
import { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import Footer from "../../components/Footer";

const SlideShowContainer = lazy(() => import("./SlideShowContainer"));
const ServicesGrid = lazy(() => import("./ServicesGrid"));
const About = lazy(() => import("./About"));
const ContactUs = lazy(() => import("./ContactUs"));


const Home = ({ isAuthenticated }) => {
    const [checkedrole, setCheckedrole] = useState("");
    const [selectedPk, setSelectedPk] = useState(null);
    const [roleModalVisible, setRoleModalVisible] = useState(false);
    const [roles, setRoles] = useState([]);
    const token = localStorage.getItem("auth_token");
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const [contactModalVisible, setContactModalVisible] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/get_user_roles/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setRoles(data || []);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [token]);

    const openContactModal = () => {
        setContactModalVisible(true);
    };


    const handleCardSelect = (pk) => {
        setSelectedPk(pk);
    };

    useEffect(() => {
        const fetchUserRole = async () => {
            const token = localStorage.getItem("auth_token");

            if (token) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/check_user_role/`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        const userRole = data.role;
                        setCheckedrole(userRole);
                    } else {
                        console.error('Failed to check user role:', response.statusText);
                        setRoleModalVisible(true);
                    }
                } catch (error) {
                    console.error('Error checking user role:', error);
                }
            }
        };

        if (isAuthenticated) {
            fetchUserRole();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (checkedrole === 'new_user') {
            setRoleModalVisible(true);
        }
    }, [checkedrole]);

    const handleRoleSubmit = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/select_user_role/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ role_id: selectedPk }),
            });

            if (response.ok) {
                setRoleModalVisible(false);
                window.location.reload();
            } else {
                const errorData = await response.json();
                console.error("Role submission failed:", errorData);
            }
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className='main-content-wrap'>
                <div className='page-content'>
                    <Suspense fallback={null}>
                        <SlideShowContainer />
                    </Suspense>

                    <Suspense fallback={null}>
                        <ServicesGrid />
                    </Suspense>

                    <Suspense fallback={null}>
                        <About />
                    </Suspense>

                    <Suspense fallback={null}>
                        <ContactUs showModal={contactModalVisible} setShowModal={setContactModalVisible} />
                    </Suspense>
                    <Footer />
                    {!contactModalVisible && (
                        <div class="add-action-box">
                            <button class="btn btn-dark btn-lg btn-square rounded-pill" onClick={() => openContactModal()}>
                                <span><i class="fas fa-phone"></i></span>
                            </button>
                        </div>
                    )}
                </div>
            </div >
            {/* Role Modal */}
            <div className={`custom-modal ${roleModalVisible ? 'show' : ''}`} id="modal-10" tabIndex="-1">
                <div className="custom-modal-dialog">
                    <div className="custom-modal-content">
                        <div className="custom-modal-header">
                            <h5 className="custom-modal-title">Select your role</h5>
                        </div>
                        <div className="custom-modal-body">
                            <div className="profession-cards">
                                {roles.length > 0 ? (
                                    roles.map(({ id, role }) => (
                                        <div
                                            key={id}
                                            className={`card profession-card ${selectedPk === id ? 'selected' : ''}`}
                                            onClick={() => handleCardSelect(id)}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">{role}</h5>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No roles available</p>
                                )}
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleRoleSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.AuthReducer.isAuthenticated
});

export default connect(mapStateToProps)(Home);
