import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../reducer/Actions";
import '../assets/css/App.css'
import Logo_img from "../assets/images/Logo.svg"
import { Profile_default } from "../assets/images/imagespath/imagepath";



function Navbar({ logout, isAuthenticated }) {
    const [clicked, setClicked] = useState(false);
    const [medicine, setMedicine] = useState(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [role, setRole] = useState("");
    const location = useLocation();

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    // Google Analytics Tracking Code
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }

        gtag("js", new Date());
        gtag("config", "G-FG1XQEQB0P");

        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-FG1XQEQB0P";
        script.async = true;

        script.onload = () => { };

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const fetchUserRole = async () => {
            const token = localStorage.getItem("auth_token");

            if (token) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/check_user_role/`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        const userRole = data.role;
                        setRole(userRole);
                    } else {
                        console.error('Failed to check user role:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error checking user role:', error);
                }
            }
        };

        if (isAuthenticated) {
            fetchUserRole();
        }
    }, [isAuthenticated]);

    const reachGoogle = () => {
        const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        const callBackURI = process.env.REACT_APP_GOOGLE_URL;
        window.location.replace(`https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${callBackURI}&prompt=consent&response_type=code&client_id=${clientID}&scope=openid%20email%20profile&access_type=offline`);
    };

    const handleClick = () => {
        setClicked(!clicked);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const navbar2 = document.getElementById('navbar2');
            if (clicked && navbar2 && !navbar2.contains(event.target)) {
                setClicked(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [clicked]);



    const handleItemClick = (setStateFunction) => {
        setStateFunction(prevState => !prevState);
    };

    return (
        <div>
            {/* Horizontal Navbar1 */}
            <div id="navbar1" className="app-navbar horizontal">
                <div className="navbar-wrap">
                    <button class="no-style navbar-toggle navbar-open d-lg-none" onClick={handleClick}>
                        <span></span><span></span><span></span>
                    </button>
                    <div className="app-logo">
                        <div className="logo-wrap">
                            <img src={Logo_img} alt="Satayush Logo" className="logo-image" />
                            <h2 className="logo-text">SATAYUSH</h2>
                        </div>
                    </div>
                    <form class="app-search d-none d-md-block">
                        <div class="form-group typeahead__container with-suffix-icon mb-0">
                            <div class="typeahead__field">
                                <div class="typeahead__query">
                                    <input
                                        class="form-control autocomplete-control topbar-search"
                                        type="search"
                                        placeholder="Satayush"
                                        autocomplete="off"
                                        data-source="../assets/data/search-menu.json"
                                    />
                                    <div class="suffix-icon">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {isAuthenticated && (
                        <div class="app-actions">
                            <div class="dropdown item">
                                <button
                                    class="no-style dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    data-bs-offset="0, 10"
                                    onClick={toggleDropdown}
                                >
                                    <span class="d-flex align-items-center">
                                        <img src={Profile_default} alt="" width="40" height="40" class="rounded-500 me-1" />
                                        <i class="fas fa-chevron-down arrow-down"></i>
                                    </span>
                                </button>
                                <div
                                    className={`dropdown-menu dropdown-menu-right dropdown-menu-w-180 ${isDropdownVisible ? 'show' : ''
                                        }`}
                                >
                                    <ul className="list">
                                        {/* <li>
                                            <a href="/edit-doctor" className="align-items-center">
                                                <i className="fas fa-user-edit me-2"></i>
                                                Edit Account
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/profile" className="align-items-center">
                                                <i className="fas fa-user me-2"></i>
                                                User Profile
                                            </a>
                                        </li> */}
                                        <li>
                                            <a href="/" className="align-items-center" onClick={logout}>
                                                <i className="fas fa-sign-out-alt me-2"></i>
                                                Log Out
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* End Horizontal Navbar1 */}
            {/* Horizontal Navbar2 */}
            <div id="navbar2" className={`app-navbar horizontal horizontal-vertical  ${clicked ? 'opened' : ''}`}>
                <div className="navbar-wrap">
                    <div className="app-logo">
                        <div className="logo-wrap">
                            <img src={Logo_img} alt="Satayush Logo" className="logo-image" />
                            <h2 className="logo-text">SATAYUSH</h2>
                        </div>
                    </div>
                    <div class="main-menu">
                        <nav class="main-menu-wrap">
                            <ul class="menu-ul">
                                <li className={`menu-item ${location.pathname === '/' && !location.hash ? 'active' : ''}`}>
                                    <a class="item-link" href="/">
                                        <span class="link-text">Home</span>
                                    </a>
                                </li>
                                {isAuthenticated && (
                                    <>
                                        {role === "Doctor" && (
                                            <li className={`menu-item has-sub  ${medicine ? 'active' : ''}`}>
                                                <a class="item-link" href="#" onClick={() => handleItemClick(setMedicine)}>
                                                    <span class="link-text">Services</span>
                                                    <i class="fas fa-chevron-down arrow-down"></i>
                                                </a>
                                                <ul class="sub">
                                                    <li class="menu-item">
                                                        <a class="item-link" href="services">
                                                            <span class="link-text">Care Services</span>
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a class="item-link" href="nurses">
                                                            <span class="link-text">Nurses</span>
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a class="item-link" href="patients">
                                                            <span class="link-text">Patients</span>
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a class="item-link" href="take-carers">
                                                            <span class="link-text">Take Carers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        )}
                                        {role === "Nurse" && (
                                            <li className={`menu-item has-sub  ${medicine ? 'active' : ''}`}>
                                                <a class="item-link" href="#" onClick={() => handleItemClick(setMedicine)}>
                                                    <span class="link-text">Services</span>
                                                    <i class="fas fa-chevron-down arrow-down"></i>
                                                </a>
                                                <ul class="sub">
                                                    <li class="menu-item">
                                                        <a class="item-link" href="services">
                                                            <span class="link-text">Care Services</span>
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a class="item-link" href="doctors">
                                                            <span class="link-text">Doctors</span>
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a class="item-link" href="patients">
                                                            <span class="link-text">Patients</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        )}
                                        {role === "Patient" && (
                                            <>
                                                <li className={`menu-item has-sub  ${medicine ? 'active' : ''}`}>
                                                    <a class="item-link" href="#" onClick={() => handleItemClick(setMedicine)}>
                                                        <span class="link-text">Services</span>
                                                        <i class="fas fa-chevron-down arrow-down"></i>
                                                    </a>
                                                    <ul class="sub">
                                                        <li class="menu-item">
                                                            <a class="item-link" href="services">
                                                                <span class="link-text">Care Services</span>
                                                            </a>
                                                        </li>
                                                        <li class="menu-item">
                                                            <a class="item-link" href="doctors">
                                                                <span class="link-text">Doctors</span>
                                                            </a>
                                                        </li>
                                                        <li class="menu-item">
                                                            <a class="item-link" href="nurses">
                                                                <span class="link-text">Nurses</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </>
                                        )}
                                        {role === "TakeCarer" && (
                                            <li className={`menu-item has-sub  ${medicine ? 'active' : ''}`}>
                                                <a class="item-link" href="#" onClick={() => handleItemClick(setMedicine)}>
                                                    <span class="link-text">Services</span>
                                                    <i class="fas fa-chevron-down arrow-down"></i>
                                                </a>
                                                <ul class="sub">
                                                    {/* <li class="menu-item">
                                                        <a class="item-link" href="appointments">
                                                            <span class="link-text">Appointments</span>
                                                        </a>
                                                    </li> */}
                                                    <li class="menu-item">
                                                        <a class="item-link" href="services">
                                                            <span class="link-text">Care Services</span>
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a class="item-link" href="doctors">
                                                            <span class="link-text">Doctors</span>
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a class="item-link" href="nurses">
                                                            <span class="link-text">Nurses</span>
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a class="item-link" href="patients">
                                                            <span class="link-text">Patients</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        )}
                                        {role === "new_user" && (
                                            <>
                                                <li className={`menu-item has-sub  ${medicine ? 'active' : ''}`}>
                                                    <a class="item-link" href="services" onClick={() => handleItemClick(setMedicine)}>
                                                        <span class="link-text">Select Role</span>
                                                        <i class="fas fa-chevron-down arrow-down"></i>
                                                    </a>
                                                    <ul class="sub">
                                                        <li class="menu-item">
                                                            <a class="item-link" href="appointments">
                                                                <span class="link-text">Appointments</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </>
                                        )}
                                        <li className="menu-item ">
                                            <a className="item-link" href="/">
                                                <span className="link-text" onClick={logout}>Logout</span>
                                            </a>
                                        </li>
                                    </>
                                )}
                                {!isAuthenticated && (
                                    <>
                                        <li className={`menu-item ${location.hash === '#care-services' ? 'active' : ''}`}>
                                            <a className="item-link" href="#care-services" onClick={handleClick}>
                                                <span className="link-text">Services</span>
                                            </a>
                                        </li>
                                        <li className={`menu-item ${location.hash === '#about-us' ? 'active' : ''}`}>
                                            <a className="item-link" href="#about-us" onClick={handleClick}>
                                                <span className="link-text">About Us</span>
                                            </a>
                                        </li>
                                        <li className={`menu-item ${location.hash === '#contact-us' ? 'active' : ''}`}>
                                            <a className="item-link" href="#contact-us" onClick={handleClick}>
                                                <span className="link-text">Contact Us</span>
                                            </a>
                                        </li>
                                        <li className="menu-item ">
                                            <a className="item-link" href=" #" onClick={reachGoogle}>
                                                <span className="link-text">Login</span>
                                            </a>
                                        </li>
                                    </>
                                )}

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* End Horizontal Navbar2 */}
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.AuthReducer.isAuthenticated,
    }
}

export default connect(mapStateToProps, { logout })(Navbar)

