import TYPE from "./Type";
import axios from "axios";
axios.defaults.withCredentials = true

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const closeAlert = () => dispatch => {
    dispatch({
        type: TYPE.CLOSE_ALERT
    })
}

export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    const body = JSON.stringify({ email, password })
    try {
        const res = await axios.post(`${API_BASE_URL}/dj-rest-auth/login/`, body, config)
        localStorage.setItem("auth_token", res.data.access);
        dispatch({
            type: TYPE.LOGIN_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: TYPE.LOGIN_FAIL
        })
    }
}

export const verify = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        const body = JSON.stringify({ "token": localStorage.getItem('access') });
        try {
            await axios.post(`${API_BASE_URL}/dj-rest-auth/token/verify/`, body, config);
            dispatch({
                type: TYPE.VERIFY_SUCCESS
            });
        } catch (err) {
            dispatch({
                type: TYPE.VERIFY_FAIL
            });
            await dispatch(refresh());
        }
    } else {
        dispatch({
            type: TYPE.GUEST_VIEW
        });
    }
};

export const getUser = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`
            }
        };
        try {
            const res = await axios.get(`${API_BASE_URL}/dj-rest-auth/user/`, config);
            dispatch({
                type: TYPE.GET_USER_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: TYPE.GET_USER_FAIL
            });
        }
    } else {
        dispatch({
            type: TYPE.GUEST_VIEW
        });
    }
}

export const refresh = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        try {
            const res = await axios.post(`${API_BASE_URL}/dj-rest-auth/token/refresh/`, config);
            console.log(res.data);
            localStorage.setItem("auth_token", res.data.access);
            dispatch({
                type: TYPE.REFRESH_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            console.log(err);
            dispatch({
                type: TYPE.REFRESH_FAIL
            })
        }
    } else {
        dispatch({
            type: TYPE.GUEST_VIEW
        })
    }
}


export const logout = () => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    try {
        await axios.post(`${API_BASE_URL}/dj-rest-auth/logout/`, config);
        dispatch({
            type: TYPE.LOGOUT
        });
    } catch (err) {
        dispatch({
            type: TYPE.LOGOUT
        });
    }
}

export const googleLogin = (code) => async dispatch => {
    if (!localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        const body = JSON.stringify({ code })

        try {
            const res = await axios.post(`${API_BASE_URL}/dj-rest-auth/google/`, body, config)
            localStorage.setItem("auth_token", res.data.access);

            dispatch({
                type: TYPE.LOGIN_SUCCESS,
                payload: res.data
            })
            
        } catch (err) {
            console.log("Error Response:", err.response ? err.response.data : err.message);

            dispatch({
                type: TYPE.LOGIN_FAIL
            })
        }
    } else {
        dispatch(verify());
        dispatch(getUser());
    }
}

